@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply font-sans text-gray-800;
  }
}

/* Component styles */
@layer components {
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-300;
  }
  .btn-primary {
    @apply bg-primary text-white hover:bg-primary/90 hover:scale-105;
  }
  .btn-secondary {
    @apply bg-secondary text-white hover:bg-secondary/90 hover:scale-105;
  }
  .container-padding {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
}

/* Custom Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Animation Classes */
.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-slide-up {
  animation: slideUp 0.6s ease-out forwards;
}

.animate-scale-in {
  animation: scaleIn 0.6s ease-out forwards;
}

/* Delay utilities */
.delay-100 {
  animation-delay: 100ms;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-300 {
  animation-delay: 300ms;
}

.delay-400 {
  animation-delay: 400ms;
}

/* Hover Effects */
.hover-scale {
  @apply transition-transform duration-300 hover:scale-105;
}

.hover-lift {
  @apply transition-all duration-300 hover:-translate-y-2 hover:shadow-lg;
}

.image-zoom {
  @apply transition-transform duration-300;
}

.image-zoom:hover {
  @apply scale-110;
}

/* Image overlay effects */
.overlay {
  @apply absolute inset-0 bg-black opacity-0 transition-opacity duration-300;
}

.group:hover .overlay {
  @apply opacity-30;
}

/* Text effects */
.text-gradient {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary;
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slideDown {
  animation: slideDown 0.5s ease-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animation Classes */
.animate-slideDown {
  animation: slideDown 0.5s ease-out forwards;
}

.animate-slideUp {
  animation: slideUp 0.5s ease-out forwards;
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out forwards;
}

/* Add these utility classes for transition effects */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-500 {
  transition-duration: 500ms;
}

/* Hover utilities */
.hover\:scale-105:hover {
  transform: scale(1.05);
}

.hover\:-translate-y-2:hover {
  transform: translateY(-8px);
}

.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

/* Active state utilities */
.active\:scale-95:active {
  transform: scale(0.95);
}

/* Group hover utilities */
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:scale-110 {
  transform: scale(1.1);
}

.group:hover .group-hover\:translate-y-0 {
  transform: translateY(0);
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation classes */
.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-slide-up {
  opacity: 0;
  transform: translateY(20px);
}

.animate-slide-up-visible {
  animation: slideUp 0.6s ease-out forwards;
}

/* Delay utilities */
.animation-delay-100 {
  animation-delay: 100ms;
}

.animation-delay-200 {
  animation-delay: 200ms;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-400 {
  animation-delay: 400ms;
}


.dropdown-fade-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}

.dropdown-fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}